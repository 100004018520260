define("discourse/plugins/discourse-mycad-menu/discourse/connectors/below-footer/mycad-menu", ["exports", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/object", "discourse/lib/plugin-api"], function (_exports, _ajax, _ajaxError, _object, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = (_obj = {
    name: 'discourse-mycad-menu',
    shouldRender(args, component) {
      return true;
    },
    initialize() {
      // moved to theme
      /*withPluginApi("0.12.1", (api) => {
        api.onAppEvent('composer:opened', () => {
          if(!($("#mycad-menu-open").hasClass("open")) && getSize() < t) {
            $("#mycad-menu-open").click();
          }
        });
      });*/
    },
    setupComponent(args, component) {
      (0, _ajax.ajax)("/mycad/get-menu").then(result => {
        let userMenu = JSON.parse(result['user_menu']);
        userMenu = Object.keys(userMenu).map(key => {
          userMenu[key]['id'] = key;
          userMenu[key]['items'] = userMenu[key]['items'].map(item => {
            if (!item.link.startsWith('http')) item.link = this.siteSettings.mycad_host + '/' + I18n.locale + item.link;
            return item;
          });
          return userMenu[key];
        });
        component.set("userMenu", userMenu);
        this.toggleMenuOpen(null);
      }).catch(_ajaxError.popupAjaxError);
      $(document).on("click", "#mycad-menu-block .title", function (e) {
        $("#mycad-menu-block .title").not(this).removeClass("open");
        $("#mycad-menu-block .menu-menu-exchange-visiativ .title").addClass("open");
        $(this).toggleClass("open");
      });
    },
    toggleMenuOpen(event) {
      var currentState = localStorage.getItem("menuState");
      if (event != null && event.type == "click") {
        if ($('#mycad-menu-open').hasClass("open")) {
          localStorage.setItem("menuState", "open");
          $('#mycad-menu-block').show();
          $('#mycad-menu-open').removeClass("open");
        } else {
          localStorage.setItem("menuState", "closed");
          $('#mycad-menu-block').hide();
          $('#mycad-menu-open').addClass("open");
        }
      } else {
        if (currentState != "closed") {
          localStorage.setItem("menuState", "open");
          $('#mycad-menu-block').show();
          $('#mycad-menu-open').removeClass("open");
        }
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "toggleMenuOpen", [_object.action], Object.getOwnPropertyDescriptor(_obj, "toggleMenuOpen"), _obj)), _obj);
});